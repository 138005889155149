import { Fragment, useState, useRef, useCallback } from "react";
import { toPng } from "html-to-image";
import styled from "styled-components";
import kevinImage from "./assets/kevin.png";

const Container = styled.div`
  padding: 10px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  height: 100%;
  margin-bottom: 50px;
`;

const Header = styled.header`
  margin: 0 auto;
  padding: 10px;
  text-align: center;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
`;

const MemeText = styled.span`
  font-size: 1.7rem;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: white;
  width: 100%;
  text-align: center;
  -webkit-text-stroke: 1px black;
  padding: 0px 3px;
  word-break: break-word;

  position: absolute;
  left: 0px;
  z-index: 10;

  ${(props) =>
    props.top &&
    `
    top: 10px;
  `}

  ${(props) =>
    props.bottom &&
    `
    bottom: 10px;
  `}
`;

const FormContainer = styled.div`
  text-align: center;
  margin: 15px;
`;

const InputContainer = styled.div`
  margin: 10px;
  display: block;
  margin: 10px auto;
  width: 80%;
  max-width: 400px;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 30px;
  margin: 4px;
`;

const App = () => {
  const imageRef = useRef(null);
  const [topText, setTopText] = useState("");
  const [bottomText, setBottomText] = useState("");

  const updateTopText = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setTopText(value);
  };

  const updateBottomText = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setBottomText(value);
  };

  const handleImageDownload = useCallback(() => {
    if (imageRef.current === null) {
      return;
    }

    toPng(imageRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "roast-kevin.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [imageRef]);

  return (
    <Fragment>
      <Container>
        <Header>
          <h1>Roast Kevin</h1>
          <h3>Type in some text, make a meme, post it for the world to see.</h3>
        </Header>
        <ImageContainer ref={imageRef}>
          <Image src={kevinImage} alt="dirtbag" />
          <MemeText top>{topText}</MemeText>
          <MemeText bottom>{bottomText}</MemeText>
        </ImageContainer>
        <FormContainer>
          <InputContainer>
            <label>Top Text</label>
            <Input
              type="text"
              placeholder="Type in top text..."
              value={topText}
              onChange={updateTopText}
            />
          </InputContainer>
          <InputContainer>
            <label>Bottom Text</label>
            <Input
              type="text"
              placeholder="Type in bottom text..."
              value={bottomText}
              onChange={updateBottomText}
            />
          </InputContainer>
          <button onClick={handleImageDownload}>Download</button>
        </FormContainer>
      </Container>
    </Fragment>
  );
};

export default App;
